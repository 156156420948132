import { css, cx } from '@emotion/css';
import isHotkey from 'is-hotkey';
import { useCallback, useMemo } from 'react';
import {
  Editor,
  Element as SlateElement,
  Transforms,
  createEditor
} from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, useSlate, withReact } from 'slate-react';

const HOTKEYS = {
  'mod+`': 'code',
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline'
};
const LIST_TYPES = ['bulleted-list', 'numbered-list'];
const TEXT_ALIGN_TYPES = ['center', 'justify', 'left', 'right'];

const markButtons = [
  {
    format: 'bold',
    icon: 'format_bold'
  },
  {
    format: 'italic',
    icon: 'format_italic'
  },
  {
    format: 'underline',
    icon: 'format_underlined'
  },
  {
    format: 'code',
    icon: 'code'
  }
];

const blockButtons = [
  {
    format: 'heading-one',
    icon: 'looks_one'
  },
  {
    format: 'heading-two',
    icon: 'looks_two'
  },
  {
    format: 'heading-three',
    icon: 'looks_3'
  },
  { format: 'block-quote', icon: 'format_quote' },
  { format: 'left', icon: 'format_align_left' },
  { format: 'center', icon: 'format_align_center' },
  { format: 'right', icon: 'format_align_right' },
  { format: 'justify', icon: 'format_align_justify' },
  {
    format: 'numbered-list',
    icon: 'format_list_numbered'
  },
  {
    format: 'bulleted-list',
    icon: 'format_list_bulleted'
  }
];

const defaultRichTextValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];

const Button = ({ active, reversed, className, ...props }) => {
  const deActiveColor = active ? 'white' : '#aaa';
  const activeColor = active ? 'black' : '#ccc';
  const reversedColor = reversed ? deActiveColor : activeColor;
  return (
    <span
      {...props}
      className={cx(
        className,
        css`
          cursor: pointer;
          color: ${reversedColor};
        `
      )}
    />
  );
};

const Icon = ({ className, ...props }) => (
  <span
    {...props}
    className={cx(
      'material-icons',
      className,
      css`
        font-size: 18px;
        vertical-align: text-bottom;
      `
    )}
  />
);

const Menu = ({ className, ...props }) => (
  <div
    {...props}
    className={cx(
      className,
      css`
        & > * {
          display: inline-block;
        }
        & > * + * {
          margin-left: 15px;
        }
      `
    )}
    data-test-id='menu'
  />
);

const Toolbar = ({ className, ...props }) => (
  <Menu
    {...props}
    className={cx(
      className,
      css`
        position: relative;
        padding: 1px 18px 17px;
        margin: 0 -20px;
        border-bottom: 2px solid #eee;
      `
    )}
  />
);

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format
    })
  );

  return !!match;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format
    };
  } else {
    const listProperty = isList ? 'list-item' : format;
    newProperties = {
      type: isActive ? 'paragraph' : listProperty
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote
          style={style}
          {...attributes}
        >
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul
          style={(style, { listStyle: 'inside', listStyleType: 'disc' })}
          {...attributes}
        >
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1
          className='text-2xl'
          style={style}
          {...attributes}
        >
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2
          className='text-xl'
          style={style}
          {...attributes}
        >
          {children}
        </h2>
      );
    case 'heading-three':
      return (
        <h3
          className='text-lg'
          style={style}
          {...attributes}
        >
          {children}
        </h3>
      );
    case 'list-item':
      return (
        <li
          style={style}
          {...attributes}
        >
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol
          style={(style, { listStyle: 'auto', paddingLeft: '20px' })}
          {...attributes}
        >
          {children}
        </ol>
      );
    default:
      return (
        <p
          style={style}
          {...attributes}
        >
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  const { bold, code, italic, underline } = leaf;
  let value = children;
  if (bold) {
    value = <strong>{value}</strong>;
  }
  if (code) {
    value = (
      <code
        style={{ color: 'black', backgroundColor: '#eee', display: 'table' }}
      >
        {value}
      </code>
    );
  }
  if (italic) {
    value = <em>{value}</em>;
  }
  if (underline) {
    value = <u>{value}</u>;
  }
  return <span {...attributes}>{value}</span>;
};

const RichTextEditor = ({
  dbKey,
  editorStyle,
  readOnly = false,
  setValue,
  values
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const initialValue = values || defaultRichTextValue;

  return (
    <Slate
      {...{
        editor,
        initialValue,
        onChange: (value) => {
          const isAstChange = editor.operations.some(
            (op) => op.type !== 'set_selection'
          );
          if (isAstChange) {
            setValue(dbKey, value);
          }
        }
      }}
    >
      {!readOnly && (
        <Toolbar>
          {markButtons.map(({ format, icon }) => (
            <MarkButton
              key={format}
              {...{
                format,
                icon
              }}
            />
          ))}
          {blockButtons.map(({ format, icon }) => (
            <BlockButton
              key={format}
              {...{
                format,
                icon
              }}
            />
          ))}
        </Toolbar>
      )}

      <Editable
        {...{
          autoFocus: true,
          className: `${editorStyle} outline-none`,
          disableDefaultStyles: false,
          onKeyDown: (event) => {
            Object.keys(HOTKEYS).forEach((hotkey) => {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            });
          },
          readOnly,
          renderElement,
          renderLeaf,
          spellCheck: true
        }}
      />
    </Slate>
  );
};

export default RichTextEditor;
