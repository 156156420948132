import Image from 'next/image';
import PropTypes from 'prop-types';

const defaultTitleClassName =
  'text-center font-family:Brandon Text items-center w-40 font-semibold mt-5 mb-5 uppercase';

const Icon = ({ url, title, alt = '', onClick = () => {}, ...props }) => (
  <div>
    <div className='rounded-full w-15 xxxs:w-full mx-auto text-center'>
      {props.href ? (
        <a
          onClick={onClick}
          href={props.href}
        >
          <Image
            alt={alt}
            width={160}
            height={120}
            layout='responsive'
            style={{ zIndex: '-1' }}
            {...{ ...props, className: props.className, src: url }}
          />
        </a>
      ) : (
        <Image
          alt={alt}
          width={160}
          height={120}
          layout='responsive'
          {...{ ...props, src: url }}
        />
      )}
    </div>
    {props.href ? (
      <a
        onClick={onClick}
        href={props.href}
      >
        <div className={defaultTitleClassName}>{title}</div>
      </a>
    ) : (
      <div className={defaultTitleClassName}>{title}</div>
    )}
  </div>
);

export default Icon;

Icon.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  alignment: PropTypes.string,
  alt: PropTypes.string,
  href: PropTypes.string
};
