const Radio = ({
  checked,
  direction = 'left',
  disabled = false,
  label,
  labelClass = 'text-sm font-medium',
  name = 'radio',
  radioClass,
  register,
  value,
  dbName
}) => (
  <label
    className={`radio-container ${radioClass} w-full justify-between relative flex  ${
      direction === 'left' ? 'flex-row' : 'flex-row-reverse'
    }`}
  >
    <div
      className={`relative top-0.5 ${
        direction === 'left' ? 'left-0' : '-left-4'
      }`}
    >
      <input
        {...{
          ...register(dbName),
          type: 'radio',
          name,
          value,
          checked,
          disabled,
          className: 'self-center flex'
        }}
      />
      <span className={`radiomark`}></span>
    </div>
    {label && <span className={labelClass}>{label}</span>}
  </label>
);

export default Radio;
