const ChargesLabel = ({ children, isDiscount = false }) => (
  <span
    className={`font-above-normal text-sm ${
      isDiscount ? 'text-green' : 'text-dim-gray'
    }`}
  >
    {children}
  </span>
);

export default ChargesLabel;
