import Image from 'next/image';
import PropTypes from 'prop-types';

import { staticMediaStoreBaseURL } from '@/config/common';

const ModalButtons = ({ operation, setShowModal }) => (
  <div className='pt-9 mx-auto flex flex-row-reverse justify-between items-center'>
    {operation && (
      <button
        type='button'
        onClick={() => {
          operation();
          setShowModal(false);
        }}
        className='text-sm md:text-base bg-coral text-white rounded-lg p-4 leading-4 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2'
      >
        Confirm
      </button>
    )}
    <button
      type='button'
      onClick={() => setShowModal(false)}
      className={`justify-center rounded-md px-4 py-2 md:py-4 text-sm md:text-base font-medium text-gray-700 hover:bg-platinum focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ms-3 sm:w-auto sm:text-sm`}
    >
      {operation ? 'Cancel' : 'Ok'}
    </button>
  </div>
);

const ModalCloseButton = ({ setShowModal }) => (
  <div
    className='absolute top-4 right-4 z-50 flex justify-end cursor-pointer'
    onClick={() => setShowModal(false)}
  >
    <Image
      src={`${staticMediaStoreBaseURL}/icons/close-gray.svg`}
      width={15}
      height={15}
      alt='close'
    ></Image>
  </div>
);

const ModalContent = ({ modalText }) => (
  <div className='bg-white w-full'>
    <div className='text-center'>
      <h3
        className='text-base md:text-xl leading-6 w-full font-medium text-gray-900'
        id='modal-title'
      >
        {modalText}
      </h3>
    </div>
  </div>
);

const ConfirmationModal = (context) => (
  <div
    aria-labelledby='modal-title'
    aria-modal='true'
    className='fixed z-50 inset-0 overflow-y-auto animated fade-in'
    role='dialog'
  >
    <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
      <div
        aria-hidden='true'
        className='fixed inset-0 bg-nero bg-opacity-80 transition-opacity'
      ></div>
      <span
        aria-hidden='true'
        className='hidden sm:inline-block sm:align-middle sm:h-screen'
      >
        &#8203;
      </span>
      <div
        className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full mx-auto px-15 md:px-17.5 py-8 md:py-12 md:w-100`}
      >
        <ModalCloseButton {...context} />
        <div className='w-9/10 mx-auto'>
          <ModalContent {...context} />
          <ModalButtons {...context} />
        </div>
      </div>
    </div>
  </div>
);

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  modalText: PropTypes.string,
  operation: PropTypes.func,
  setShowModal: PropTypes.func
};
