import PropTypes from 'prop-types';

const Paragraph = ({ content, ...property }) => (
  <p
    {...property}
    className={`${property.className || ''} `}
  >
    {content}
  </p>
);

export default Paragraph;

Paragraph.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string
};
