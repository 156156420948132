import PropTypes from 'prop-types';
import Select from 'react-select';

const Dropdown = ({
  className,
  clearErrors,
  control,
  dbName,
  defaultValueIndex,
  errors,
  getValues,
  isClearable,
  isDisabled,
  isLoading,
  isMulti,
  isSearchable,
  name,
  onChange,
  options,
  placeholder = 'Select...',
  register,
  setValue,
  ...rest
}) => {
  const selectedValue = options.find(
    ({ value }) => value === getValues(dbName)
  );
  return (
    <div className='w-full'>
      <Select
        {...{
          ...register(dbName),
          className,
          classNamePrefix: 'select',
          closeMenuOnSelect: !isMulti,
          defaultValue: selectedValue,
          isClearable,
          isDisabled,
          isLoading,
          isMulti,
          isOptionDisabled: ({ disabled }) => disabled,
          isSearchable,
          name,
          onChange,
          options,
          placeholder,
          value: selectedValue,
          styles: {
            dropdownIndicator: (provided, state) => ({
              ...provided,
              transition: 'all .2s ease',
              transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
            })
          },
          ...rest
        }}
      />
    </div>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  defaultValueIndex: PropTypes.number,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired
};

Dropdown.defaultProps = {
  isClearable: false,
  isDisabled: false,
  isLoading: false,
  isMulti: false,
  isSearchable: false
};
