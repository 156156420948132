import { staticMediaStoreBaseURL } from '@/config/common';

const Loader = () => (
  <div className='loading-bg h-screen flex'>
    <img
      alt='loading image'
      className='self-center mx-auto w-12'
      src={`${staticMediaStoreBaseURL}/icons/loading.gif`}
    />
  </div>
);

export default Loader;
