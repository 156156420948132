import PropTypes from 'prop-types';

const Container = ({ children, ...props }) => (
  <div
    {...{
      ...props,
      className: ['justify-items-center mt-10 mb-5', props.className || '']
        .join(' ')
        .trim()
    }}
  >
    {' '}
    {children}
  </div>
);

export default Container;

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
