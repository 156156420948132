import PropTypes from 'prop-types';

const InputLabel = ({ children, labelFor, required, ...rest }) => (
  <label
    {...{
      htmlFor: `${labelFor}`,
      className: `cursor-pointer ${rest.className}`
    }}
  >
    {children}
    {required && (
      <span
        aria-hidden
        className='text-cinnabar'
      >
        *
      </span>
    )}
  </label>
);

export default InputLabel;

InputLabel.propTypes = {
  labelFor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  required: PropTypes.bool
};
