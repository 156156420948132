import PropTypes from 'prop-types';

const classNameTitle =
  'font-google-basic text-4xl text-nero uppercase leading-8 lg:leading-16 text-start tracking-wider';
const CollectionSubHeader = ({ title, subTitle, underline, ...props }) => (
  <div>
    {props.subPosition === 'top' && <SubTitle {...{ props, subTitle }} />}
    <div className='flex flex-row'>
      <div
        style={{ letterSpacing: 7 }}
        className={`${classNameTitle} ${
          props.titleClass ? props.titleClass : ''
        }`}
      >
        {title}
      </div>
      {underline && (
        <hr
          style={{
            height: 5,
            width: 80,
            borderRadius: 20,
            background:
              'linear-gradient(50deg, rgb(220, 124, 10) 7.01%, rgb(202, 87, 121) 56.57%, rgb(205, 76, 91) 100.37%)',
            alignSelf: 'center',
            marginLeft: 20
          }}
        />
      )}
    </div>
    {props.subPosition === 'bottom' && <SubTitle {...{ props, subTitle }} />}
  </div>
);

const SubTitle = ({ props, subTitle }) => (
  <div
    className={`font-Montserrat text-nero text-xs md:text-xl leading-4 font-bold mt-1 text-start ${
      props.subTitleClass ? props.subTitleClass : ''
    }`}
  >
    {subTitle}
  </div>
);

export default CollectionSubHeader;
CollectionSubHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleClass: PropTypes.string,
  subTitleClass: PropTypes.string,
  subTitle: PropTypes.string,
  underline: PropTypes.bool,
  subPosition: PropTypes.string
};
