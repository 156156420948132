import { Fragment } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { PRODUCT_TYPE } from '@/config/common';
import {
  formatDiscount,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';

const QuoteProductPriceLabel = ({ price }) => (
  <div className='text-sm md:text-lg lg-l:text-xl mt-4'>
    Price Starting from AED {parseFormatPriceValueFromAPI(price?.minPrice || 0)}
  </div>
);

const CartProductPriceLabel = ({ availableDiscount, price }) => (
  <div>
    <div className='flex font-400 items-center'>
      <div className='text-sm md:text-lg font-semibold'>
        AED {parseFormatPriceValueFromAPI(price?.priceToApply || 0)}
      </div>
      {Boolean(availableDiscount) && (
        <Fragment>
          <div className='line-through ms-3 text-dim-gray text-xs md:text-lg font-medium'>
            AED {parseFormatPriceValueFromAPI(price.price)}
          </div>
          <div className='ms-3 text-green text-xs md:text-lg font-medium'>
            {formatDiscount(availableDiscount.value)}% off
          </div>
        </Fragment>
      )}
    </div>
    <div className='text-xs text-brand mt-2 font-medium md:hidden'>
      Excluding VAT
    </div>
  </div>
);

const productPriceLabelMap = {
  [PRODUCT_TYPE.CART]: CartProductPriceLabel,
  [PRODUCT_TYPE.QUOTE]: QuoteProductPriceLabel
};

const ProductPriceLabel = ({ availableDiscount, price, productType }) => {
  const Label = productPriceLabelMap[productType];
  return <Label {...{ availableDiscount, price }} />;
};

export default ProductPriceLabel;
