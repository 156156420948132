import config from '@/config';

const debounce = (fn, timeout = config.debounceTime) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => fn(...args), timeout);
  };
};

export default debounce;
