import Image from 'next/image';
import PropTypes from 'prop-types';

const iconPositions = { left: 'Left', right: 'Right' };

const Button = ({
  children,
  iconUrl,
  iconPosition = null,
  imageClassName,
  iconWidth = '100',
  iconHeight = '100',
  className,
  iconGroupClassName,
  width = 'w-full',
  alt = 'cart-bag',
  ...rest
}) => (
  <button
    className={`${className} flex items-center justify-center cursor-pointer hover:opacity-90 hover:no-underline focus:outline-none focus:ring-0 ${width}`}
    {...rest}
  >
    <div
      className={`flex gap-2 ${iconGroupClassName} ${
        iconPosition === 'Right' && 'flex-row-reverse space-x-2 space-x-reverse'
      } ${iconPosition === 'Left' && 'flex-row space-x-2'} `}
    >
      {iconUrl && iconPosition && (
        <Image
          {...{
            alt,
            className: imageClassName,
            height: iconHeight,
            src: iconUrl,
            width: iconWidth
          }}
        />
      )}
      {children && (
        <span className='flex justify-center items-center'> {children} </span>
      )}
    </div>
  </button>
);

export default Button;

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  iconPosition: PropTypes.oneOf(Object.values(iconPositions)),
  iconUrl: PropTypes.string,
  imageClassName: PropTypes.string
};
