import Image from 'next/image';
import PropTypes from 'prop-types';

const defaultTitleClassName =
  'text-center font-Montserrat Text items-center text-sm font-medium w-28 mt-5 mb-5';

const IconTitle = ({ title, href }) =>
  href ? (
    <a href={href}>
      <div className={defaultTitleClassName}>{title}</div>
    </a>
  ) : (
    <div className={defaultTitleClassName}>{title}</div>
  );

const ImageContent = ({ href, alt, url: src, className }) =>
  href ? (
    <a href={href}>
      <Image
        width={50}
        height={50}
        layout='responsive'
        {...{ alt, className, src }}
      />
    </a>
  ) : (
    <Image
      width={50}
      height={50}
      layout='responsive'
      {...{ alt, className, src }}
    />
  );

const IconMobile = (props) => (
  <div>
    <div className='rounded-full xxxs:w-full mx-auto'>
      <ImageContent {...props} />
    </div>
    <IconTitle {...props} />
  </div>
);

export default IconMobile;

IconMobile.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  alignment: PropTypes.string,
  alt: PropTypes.string,
  href: PropTypes.string
};
