import Image from 'next/image';
import PropTypes from 'prop-types';
// TODO: Try using atoms.
const iconPositions = { left: 'Left', right: 'Right' };

const LoginButton = ({
  children,
  iconUrl,
  iconPosition,
  imageClassName,
  className,
  ...rest
}) => (
  <button
    className={`flex items-center justify-center cursor-pointer hover:no-underline focus:outline-none focus:ring-0 w-full ${className}`}
    {...rest}
  >
    <div
      className={`flex justify-center items-center ${
        iconPosition === 'Right' && 'flex-row-reverse space-x-2 space-x-reverse'
      } ${iconPosition === 'Left' && 'flex-row space-x-2'} `}
    >
      {iconUrl && iconPosition && (
        <Image
          alt={iconUrl}
          width={50}
          height={50}
          layout='responsive'
          src={iconUrl}
          className={imageClassName}
        />
      )}
      <span className='flex justify-center items-center'> {children} </span>
    </div>
  </button>
);

export default LoginButton;

LoginButton.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  iconUrl: PropTypes.string,
  iconPosition: PropTypes.oneOf(Object.values(iconPositions)),
  imageClassName: PropTypes.string
};
