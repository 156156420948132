import Image from 'next/image';
import DatePickerContainer from 'react-datepicker';

import { staticMediaStoreBaseURL } from '@/config/common';

const DatePicker = ({
  baseClass = 'w-full border border-gray-300 rounded-lg text-sm datepicker-input',
  className,
  dbName,
  errors,
  minDate,
  selectedDate,
  setSelectedDate,
  setValue,
  showIcon = false
}) => {
  const isInvalidMessage = errors?.[dbName]?.message;
  const calculatedMinDate = minDate ? new Date(minDate) : null;
  const availableSelectedDate = selectedDate ? new Date(selectedDate) : null;

  return (
    <div className='relative'>
      <div className='absolute w-6 h-6 top-2 left-1'>
        <Image
          alt='date-icon-black'
          className='cursor-pointer absolute top-4 z-50 left-0'
          height={20}
          src={`${staticMediaStoreBaseURL}/icons/calendar-dates-gray.svg`}
          width={20}
          layout='fill'
        />
      </div>
      <DatePickerContainer
        showIcon={showIcon}
        minDate={calculatedMinDate}
        selected={availableSelectedDate}
        onChange={(e) => {
          setValue && setValue(dbName, new Date(e));
          setSelectedDate(e);
        }}
        openToDate={availableSelectedDate || calculatedMinDate}
        placeholderText='MM/DD/YYYY'
        calendarClassName=''
        className={`${className} ${baseClass}`}
      />
      {isInvalidMessage && (
        <div className='text-xs text-red-500'>{isInvalidMessage}</div>
      )}
    </div>
  );
};

export default DatePicker;
