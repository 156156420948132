import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const AlertMessage = ({ message }) => {
  const [isMobile] = useIsMobile();
  return (
    <div className='flex md:gap-1 gap-3 bg-unavailable-error p-4'>
      <Image
        src={`${staticMediaStoreBaseURL}/icons/failed.svg`}
        width={14}
        height={14}
        alt='error'
        layout={isMobile ? 'fixed' : 'intrinsic'}
      />
      <p className='text-error-base font-medium text-xs flex-1 md:text-sm'>
        {message}
      </p>
    </div>
  );
};

export default AlertMessage;
