import { Fragment } from 'react';

// TODO: Faizan to sort keys here, and rest everywhere related to tender-response, ordered cart, readonly order planner cart
const TextKeyValuePair = ({
  label,
  labelClass = 'text-silver uppercase text-xs md:text-sm',
  value,
  valueClassName = 'text-base md:text-lg',
  className = 'flex-col',
  spaceTop = 'mt-1 md:mt-0'
}) => (
  <div className={`flex gap-2 ${className} ${spaceTop}`}>
    <div className={labelClass}>{label}</div>
    <div className={valueClassName}>{value}</div>
  </div>
);

const getItemDetails = ({ containerClass, priceLabel, quantity }) => [
  {
    className: `text-xs md:text-base font-medium ${containerClass}`,
    label: 'Quantity: ',
    labelClass: '',
    value: quantity,
    valueClassName: ''
  },
  {
    className: `text-xs md:text-base font-medium ${containerClass}`,
    label: 'Total: AED ',
    labelClass: '',
    value: priceLabel,
    valueClassName: ''
  }
];

const ItemCommonDetails = (itemDetails) => (
  <Fragment>
    {getItemDetails(itemDetails).map(
      (item, index) =>
        Boolean(item.value) && (
          <TextKeyValuePair
            key={index}
            {...item}
          />
        )
    )}
  </Fragment>
);

export default ItemCommonDetails;
