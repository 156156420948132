import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const CloseIcon = ({
  className = 'flex justify-end cursor-pointer',
  height = 20,
  icon = 'close-with-border-gray.svg',
  iconClassName,
  onClick = () => {},
  width = 20,
  ...rest
}) => (
  <span
    className={className}
    onClick={onClick}
  >
    <Image
      {...{
        className: iconClassName,
        alt: 'cross',
        layout: 'fixed',
        src: `${staticMediaStoreBaseURL}/icons/${icon}`,
        width,
        height,
        ...rest
      }}
    />
  </span>
);

export default CloseIcon;
