import PropTypes from 'prop-types';

const Heading = ({ children, level = 2, ...property }) => {
  const Type = `h${level}`;
  return <Type {...property}>{children}</Type>;
};

export default Heading;

Heading.propTypes = {
  level: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node
};
