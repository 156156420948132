// TODO: Need to discuss that if it is feasible to remove dbkeyname in input.It causing error in console.
const FormInput = ({
  additionalStyleClass = '',
  dbKeyName,
  disabled = false,
  disabledBg,
  errors,
  height = 'h-5',
  label,
  others,
  placeholder = 'NA',
  register,
  type
}) => (
  <div className='flex flex-col border-platinum w-full pb-2 border-b mb-3'>
    <label className='text-sm leading-17px font-medium text-nero mb-3'>
      {label}
    </label>
    <input
      disabled={disabled}
      type={type}
      {...others}
      className={`text-sm font-medium leading-5 placeholder-silver border-none ${height} cursor-pointer focus:outline-none ${
        errors[dbKeyName] ? 'is-invalid' : ''
      } ${disabledBg ? 'disable-bg' : ''} ${additionalStyleClass}`}
      placeholder={placeholder}
      {...register(dbKeyName)}
    />
    {errors[dbKeyName] && (
      <span
        className={
          'mt-1 text-red-500 text-xs font-medium capitalize invalid-feedback'
        }
      >
        {errors[dbKeyName].message}
      </span>
    )}
  </div>
);

export default FormInput;
