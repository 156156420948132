import PropTypes from 'prop-types';

const SectionHeader = ({ heading, headingStyle }) => (
  <h2
    className={`text-nero ${
      headingStyle || 'md:text-3xl font-semibold'
    } title:size`}
  >
    {heading}
  </h2>
);

export default SectionHeader;

SectionHeader.propTypes = {
  heading: PropTypes.string,
  headingStyle: PropTypes.string
};
