import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const VideoPlayIcon = () => (
  <Image
    className='ms-1 cursor-pointer rounded-full bg-transparent flex items-center justify-center z-10'
    width={50}
    height={50}
    src={`${staticMediaStoreBaseURL}/icons/play-icon.svg`}
    alt='hafla play'
  />
);

export default VideoPlayIcon;
