import PropTypes from 'prop-types';

const PriceLabel = ({ isBracket = false, isDiscount = false }) => (
  <span
    className={`price-label ${isDiscount ? 'text-green' : 'price-label-color'}`}
  >
    {isBracket ? '(AED)' : 'AED'}
  </span>
);

export default PriceLabel;

PriceLabel.propTypes = {
  isBracket: PropTypes.bool
};
