const phoneConfig = {
  phoneNumbers: {
    haflaLandline: '+97145616897',
    customerService: '+971501169428',
    internalNotification: '+971544430762'
  },
  mobileInputError: 'Invalid mobile number',
  firstCountryToShow: ['AE', 'SA', 'IN'],
  mobileNotStartZeroRegex: /^[1-9][0-9]{3,13}$/,
  mobileBasicValidationFailureError:
    'Only 9 or 10 digits are allowed, please exclude country code or 0'
};

export default phoneConfig;
