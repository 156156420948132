import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { staticMediaStoreBaseURL } from '@/config/common';

const ReadFullArticle = ({ slug }) => (
  <div className='pb-1 w-full'>
    <Link href={slug}>
      <div className='flex items-center'>
        <span className='text-xs font-semibold text-left blog-font-style'>
          Read Full Article
        </span>
        <div className='relative ms-1.5 h-2.5 flex'>
          <Image
            alt='right-angle'
            src={`${staticMediaStoreBaseURL}/icons/right-angle.svg`}
            height={8}
            width={10}
            layout='responsive'
          />
        </div>
      </div>
    </Link>
  </div>
);

const CardMobile = ({
  bannerImageUrl = '',
  id,
  imageBasePath,
  slug,
  title = '',
  type
}) => {
  const router = useRouter();
  const imgLinkUrl = `${imageBasePath}${bannerImageUrl}`;
  console.log({ imgLinkUrl });

  return (
    <a
      key={id}
      onClick={(e) => {
        e.preventDefault();
        if (slug) router.push(slug);
      }}
    >
      <div
        className={`flex flex-col justify-around bg-white max-w-57 p-3 min-w-57 no-scrollbar items-center ${
          type === 'blog' ? 'h-66' : 'h-64'
        }`}
      >
        <div
          className={`w-full relative overflow-hidden rounded-1.5 ${
            type === 'blog' ? 'h-37' : 'h-45'
          }`}
        >
          <Image
            src={imgLinkUrl}
            alt={title}
            className='img-stretch'
            width={50}
            height={50}
            layout='responsive'
          />
        </div>
        <div
          className={`${
            type === 'blog'
              ? 'pt-4 h-25 grid grid-cols-1 content-between'
              : 'pt-3 h-auto'
          }`}
        >
          <h3
            className={`text-15px text-nero font-Montserrat font-semibold leading-4 ${
              type === 'blog' ? '' : 'text-center'
            }`}
          >
            {title}
          </h3>
          {type === 'blog' && <ReadFullArticle slug={slug} />}
        </div>
      </div>
    </a>
  );
};

export default CardMobile;

CardMobile.propTypes = {
  bannerImageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  type: PropTypes.string
};
